






import { Component, InjectReactive, Mixins } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { Inject } from '../../../support'

import { AbstractModuleUi } from '../../abstract/ui'
import { TabsContent, TabsContainersRegistry } from '../../../dsl/molecules/Tabs'

import { TabsModule } from '../Tabs.contracts'
import {
  ALL_CONTAINERS_MOUNTED_INJECTION_KEY,
  UI_CONTAINER_ID_ATTR_PREFIX,
  UI_CONTAINER_MOUNTED_EVENTBUS_EVENT_NAME
} from '../Tabs.config'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<TabsModuleUi>({
  name: 'TabsModuleUi',
  components: {},
  mounted (): void {
    setTimeout(() => {
      this.catchContainers()
        .then((containers: TabsContainersRegistry) => {
          this.containers = containers
        })
        .catch(error => {
          console.log(error, 'error')
        })
    }, 0)
  }
})
export class TabsModuleUi extends Mixins<AbstractModuleUi<TabsModule>>(AbstractModuleUi) {
  /**
   * Eventbus injection.
   */
  @Inject(EventbusType)
  private eventBus!: IEventbus

  /**
   * Determines whether all containers have been mounted yet.
   */
  @InjectReactive(ALL_CONTAINERS_MOUNTED_INJECTION_KEY)
  public readonly allContainersMounted!: boolean

  /**
   * List of key-value pairs representing the associated containers along with their root HTML elements.
   */
  public containers: TabsContainersRegistry | null = null

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasTabs && this.hasContainers
  }

  /**
   * Determines tabs.
   */
  public get tabs (): TabsContent['tabs'] {
    return this.content.tabs
  }

  /**
   * Only those tabs which associated containers have been successfully found in the DOM.
   *
   * @see containers
   * @see catchContainers()
   */
  public get tabsWithContainers (): TabsContent['tabs'] {
    if (this.containers === null) {
      return []
    }

    return this.tabs.filter(tab => {
      // @ts-expect-error - TS does not recognise the above `null` check :)
      return Object.keys(this.containers).includes(tab.containerId)
    })
  }

  /**
   * Catches the HTML elements associated with the tabs' containers.
   */
  private async catchContainers (): Promise<TabsContainersRegistry> {
    // We have to use the `Promise` here, as the containers are being mounted
    // in a mainly random order. In other words, there's no guarantee that in the time
    // of running this method all the containers will be mounted and their root HTML elements
    // will be present in the DOM.
    const possibleContainers: PromiseSettledResult<NodeList>[] = await Promise.allSettled(
      // Loop through all the tabs and create a new `Promise` object for each of them.
      this.tabs.map<Promise<NodeList>>(({ containerId }) => {
        return new Promise<NodeList>((resolve, reject) => {
          // Try to catch the corresponding container element within the DOM.
          let containers: NodeList | null = null

          const identifiers = containerId.split(',')
          const decoratedIdentifiers: string[] = []

          identifiers.map((id) => {
            decoratedIdentifiers.push(`#${UI_CONTAINER_ID_ATTR_PREFIX}${id}`)
          })

          const selector = decoratedIdentifiers.join(',')

          /**
           * Tries to catch the container element within the DOM.
           *
           * @see container
           */
          const catchContainer = (): void => {
            containers = document.querySelectorAll<HTMLDivElement>(selector)
          }

          catchContainer()

          // If an attempt was a success (i.e. HTML element has been successfully queried)
          // resolve the `Promise` and return the caught container element.
          if (containers !== null && [...containers].length > 0) {
            return resolve(containers)
          }

          // Else, if the `querySelector()` returned `null`,
          // start watching the event bus for the upcoming events
          // with `name` property set to `UI_CONTAINER_MOUNTED_EVENTBUS_EVENT_NAME`.
          // This kind of event is being emitted when the `<UiContainer>` component gets mounted.
          this.eventBus.handle<{ id: string }>(
            UI_CONTAINER_MOUNTED_EVENTBUS_EVENT_NAME, event => {
              // When an event is intercepted, check if the corresponding `id` payload property
              // is equal to the `containerId` variable. If true, it means that the emitted event
              // relates to the same container that the tab is assigned to.
              if (event.payload?.id === containerId) {
                // Try to catch the corresponding container element within the DOM.
                catchContainer()

                if (containers === null) {
                  return reject(new Error(`Associated <UiContainer> with the ID of [${containerId}] has been mounted, but couldn't be found in DOM!`))
                }

                // If an attempt was a success (i.e. HTML element has been successfully queried)
                // resolve the `Promise` and return the caught container element.
                return resolve(containers)
              }
            })

          /**
           * Rejects the promise when all containers have been successfully mounted.
           *
           * @see reject
           */
          const rejectWhenAllContainersMounted = () => {
            if (this.allContainersMounted) {
              return reject(new Error(`Associated <UiContainer> with the ID of [${containerId}] hasn't been mounted. Aborting.`))
            }

            this.$watch('allContainersMounted', rejectWhenAllContainersMounted)
          }

          return rejectWhenAllContainersMounted()
        })
      }))

    const foundContainers: NodeList[] = []

    possibleContainers.forEach(container => {
      if (container.status === 'fulfilled') {
        foundContainers.push(container.value)
      }
    })

    return this.tabs.reduce((acc, { containerId }, index) => ({
      ...acc,
      [containerId]: foundContainers[index]
    }), {})
  }

  /**
   * Determines whether the component has successfully populated the containers registry.
   */
  private get hasContainers (): boolean {
    return this.containers !== null && Object.entries(this.containers).length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `tabs` prop.
   */
  private get hasTabs (): boolean {
    return typeof this.tabs !== 'undefined' &&
      Array.isArray(this.tabs) &&
      this.tabs.length > 0
  }
}

export default TabsModuleUi
